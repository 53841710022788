import { usePortal } from "contexts/PortalContext";
import classnames from "classnames/bind";
import settingsStyles from "../settingsDrawer.module.scss";
import { Section } from "components/Common/Section";
import { Portrait } from "components/Common/Portrait/Portrait";
import { fpSet } from "lib/fpLodash";
import { Input } from "components/Common/Input";
import styles from "./userSettings.module.scss";
import { Button } from "components/Common/Button";
import { SmallIcon } from "components/Common/SmallIcon";
import { LogOut } from "lucide-react";
import { STICKERS_LIST } from "components/OverlayButtons/ChatBox/images/stickers/stickers";

const classNameBuilder = classnames.bind(settingsStyles);

export const UserSettings = ({
  tempUser,
  setTempUser,
  onSubmitUser,
  hasUserChanges,
  resetTempUser,
  signOut,
  setIsPortalComponentRendered,
}) => {
  const imageSelectPortal = usePortal();

  return (
    <>
      <Section
        label={"User"}
        noBorder
        contentClassName={classNameBuilder("section-content", "split")}
      >
        <div className={settingsStyles["section-column"]}>
          <Section
            label={"Portrait"}
            isSubSection
            noPadding
            className={styles["entry"]}
            contentClassName={styles["portrait-container"]}
          >
            <Portrait
              className={styles["portrait"]}
              value={tempUser?.photoURL}
              imageProps={{
                className: styles["image-container"],
                round: true,
              }}
              portalProps={imageSelectPortal}
              defaultImagesSuffix={STICKERS_LIST}
              portalComponentProps={{
                onSubmit: (val) =>
                  setTempUser((prev) => fpSet("photoURL", val, prev)),
              }}
            />
          </Section>
        </div>
        <div className={settingsStyles["section-column"]}>
          <Section
            label={"Display Name"}
            isSubSection
            noPadding
            className={settingsStyles["entry"]}
          >
            <Input
              className={styles["input"]}
              value={tempUser.displayName}
              placeholder={"<no name>"}
              onChange={(e) =>
                setTempUser((prev) =>
                  fpSet("displayName", e.target.value, prev)
                )
              }
            />
          </Section>
          <Button
            onClick={() => signOut() || setIsPortalComponentRendered(false)}
            className={styles["sign-out"]}
          >
            <SmallIcon>
              <LogOut
                className={classNameBuilder("footer-icon", "sign-out-icon")}
              />
            </SmallIcon>
            {"Sign out"}
          </Button>
        </div>
      </Section>
    </>
  );
};
