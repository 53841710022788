import {
  CloudLightning,
  FileImage,
  MessageSquareText,
  Plane,
  Plus,
  TelescopeIcon,
  Upload,
} from "lucide-react";
import styles from "./changeLog.module.scss";
import { BULLET_LIST_ITEM_TYPE } from "components/Common/List/bulletListConsts";
import { purchaseLink, stableLink, supportEmail } from "constants/contactInfo";
import { Submarine } from "components/Common/Icons/Submarine";
import { GeneralLink } from "components/Footer/FooterLink";
import { SHIPS_COLLECTION } from "contexts/database/useShipAccessors";
import { fpFlow, fpMapKeys, fpPick } from "lib/fpLodash";
import { findLast, identity, keyBy, mapValues } from "lodash";
import { uuid } from "lib/uid";
import {
  DAYS_OF_THE_WEEK_OPTIONS,
  ONE_TO_FIVE_OPTIONS,
  SURVEY_TYPES,
} from "./surveys";
import { convertToDropdownOptions } from "components/Common/Roller/utils/rollerUtils";

// const wipNextVersion =

export const stableVersion = "1.6.2";

const Link = (props) => (
  <GeneralLink {...props} className={styles["version-link"]} />
);

export const versions = [
  {
    version: "1.6.6",
    date: "2024-10-11",
    changes: [
      "Added survey architecture. We would greatly appreciate if you participate in the survey below!",
      "Surveys are always anonymous and all questions are optional.",
      'If you haven\'t responded to the latest survey, a button will show up in the Changelog header labeled "Jump to Latest Survey".',
    ],
    survey: {
      description: (
        <div>
          This is our first survey! We plan on using these surveys to guide our
          choices in regards to the direction of the app!
        </div>
      ),
      questions: [
        {
          type: SURVEY_TYPES.DROPDOWN,
          options: ONE_TO_FIVE_OPTIONS,
          label:
            "On a scale of 1-5 (1 being the worst), how much are you enjoying Charsmith?",
        },
        {
          type: SURVEY_TYPES.SHORT_ANSWER,
          label: "What is something you feel like Charsmith is missing?",
          limit: 500,
        },
        {
          type: SURVEY_TYPES.DROPDOWN,
          label: "What continent are you from?",
          options: convertToDropdownOptions(
            [
              "Africa",
              "Asia",
              "Australia",
              "Europe",
              "North America",
              "South America",
            ],
            { shouldUseId: true }
          ),
          description:
            "We're asking because we want to know when it's best to rollout changes.",
        },
        {
          type: SURVEY_TYPES.MULTI_SELECT,
          label: "What days do you use Charsmith?",
          description:
            "We're asking because we want to know when it's best to rollout changes.",
          options: DAYS_OF_THE_WEEK_OPTIONS.slice(1),
        },
        {
          type: SURVEY_TYPES.MULTI_SELECT,
          label: "What pages of Charsmith do you use?",
          options: convertToDropdownOptions(
            ["Characters", "Ships", "GM", "Compendium"],
            { shouldUseId: true }
          ),
        },
      ],
    },
  },
  {
    version: "1.6.5",
    date: "2024-10-08",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Updated all collapsible areas to fully collapse by default",
        items: [
          "If you prefer the semi-collapsed behavior, you can disable the new 'Full Section Collapse' settings in the Settings Drawer.",
        ],
      },
      "Re-ordered elements in the Characters page",
    ],
  },
  {
    version: "1.6.4",
    date: "2024-10-07",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Added ability to import characters, ships, and GMs on the Groups page",
        items: [
          <span>
            Look out for the{" "}
            <Upload
              style={{
                verticalAlign: "middle",
                width: "15px",
                height: "15px",
              }}
            />{" "}
            button in the section header of the Characters, Ships, and GMs
            (located beneath the Group Info section).
          </span>,
          {
            type: BULLET_LIST_ITEM_TYPE.WARNING,
            text: "Sheets will behave as if the user performing the import created the sheet themselves, so please double check the Access Control section if users are unable to find an imported sheet.",
          },
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added ability to add and delete Reputation entries on the Ships page",
      },
    ],
  },
  {
    version: "1.6.3",
    date: "2024-10-06",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Added support for multiple hulls, bites, and engines on the Ships page!",
        items: [
          <span>
            Look out for the{" "}
            <Plus
              style={{
                verticalAlign: "middle",
                width: "15px",
                height: "15px",
              }}
            />{" "}
            button in the section header
          </span>,
        ],
      },
      "Updated stable version to v1.6.2",
    ],
    migrations: {
      [SHIPS_COLLECTION]: ({ oldData, newData, changes }) => {
        const { design, designType } = newData;
        const keyConversion = {
          SIZE: "designSize",
          FRAME: "designFrame",
          HULL: "designHull",
          BITE: "designBite",
          ENGINE: "designEngine",
        };

        const newDesigns = fpFlow(
          fpPick(Object.keys(keyConversion)),
          (prev) =>
            mapValues(prev, (value, key) => [
              {
                id: uuid(),
                label: value || "",
                type: designType?.[key] || "",
              },
            ]),
          fpMapKeys((key) => keyConversion[key])
        )(design || {});

        const newChanges = {
          ...changes,
          ...newDesigns,
        };

        return {
          oldData,
          newData: {
            ...newData,
            ...newChanges,
          },
          changes: newChanges,
        };
      },
    },
  },
  {
    version: "1.6.2",
    date: "2024-10-05",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Added ability to send images in the chat",
        items: [
          <span>
            Look out for the{" "}
            <FileImage style={{ verticalAlign: "middle", width: "15px" }} />{" "}
            button in the chat box
          </span>,
          "Includes Charsmith mascot stickers (more to come)",
        ],
      },
      "Fix bugs related to generators and expansions",
    ],
  },
  {
    version: "1.6.1",
    date: "2024-09-29",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: 'Added "Reaches (Storm and Root)" expansion',
        items: [
          <span>
            Adds <i>Carcharbora</i> and <i>Petrou</i> backgrounds to your
            campaign
          </span>,
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Added ability to ping compendium articles",
        items: [
          'Look for the "Share in Chat" button in the breadcrumbs of any compendium article.',
          "Note: you must have a group selected for the button to appear",
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added ability to hide chat messages you have sent",
        items: [
          "If you wrote a specific chat entry, you will see a small button appear on hover",
        ],
      },
    ],
  },
  {
    version: "1.6.0",
    date: "2024-09-28",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: "Storm and Root has arrived!",
      },
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Enable the expansion on the Groups page in the Expansions section",
        items: [
          "We highly recommend supporting the creators before you use the expansion content!",
          <Link href={purchaseLink} className={styles[""]}>
            Link to their online store
          </Link>,
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "New features have been added to the following pages!",
        items: [
          <span>
            Just look out for these icons:{" "}
            <CloudLightning style={{ width: 15, verticalAlign: "middle" }} />{" "}
            <Plane style={{ width: 15, verticalAlign: "middle" }} />{" "}
            <Submarine style={{ width: 15, verticalAlign: "middle" }} />
          </span>,
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Characters:",
        items: [
          "Additional options for the Character Creator and Aspects section",
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Ships:",
        items: [
          "Additional options for the Design, Fittings, and Undercrew sections",
          "New ratings related to takeoff, flight, and delving",
          "A new, ping-able section to track Scrutiny",
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "GMs:",
        items: ["A new, ping-able section to track Pressure"],
      },
    ],
  },
  {
    version: "1.5.0",
    date: "2024-09-22",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Added \"Migration\" architecture so we can modify the shape of your sheet's after you've created them.",
        items: [
          "In the near future, we'll implement support for multiple Ship Designs!",
        ],
      },
      {
        type: BULLET_LIST_ITEM_TYPE.WARNING,
        text: (
          <div>
            <span>
              If you are stuck on a screen that says "Migrating Sheet to X.X.X".
              Contact us at <u>{supportEmail}</u> and we'll try to resolve the
              issue. Until we get back to you, feel free to use the{" "}
              <Link href={stableLink}>stable version</Link> of the site.
            </span>
            <span></span>
          </div>
        ),
      },
      {
        type: BULLET_LIST_ITEM_TYPE.WARNING,
        text: "If you see a pulsing warning icon in the header, it means your sheet is on an old version of the app and you may lose data if you keep editing the sheet!",
      },
      "Updated stable version to 1.4.3",
    ],
  },
  {
    version: "1.4.3",
    date: "2024-09-20",
    changes: [
      "Updated many images in the app and compendium",
      "Added background to transparent images in the Character, Ship, and Groups page",
      {
        type: BULLET_LIST_ITEM_TYPE.WARNING,
        text: "If you're using a preset ship image, your image may fail to load. Please click the pencil icon and re-select the preset. We apologize for the inconvenience!",
      },
    ],
  },
  {
    version: "1.4.2",
    date: "2024-09-16",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Added ability to print the app using CTRL + P",
        items: [
          "A small window with a screencap of the app in a light gray theme will open.",
          "Click the 'Download' button to download the image to your computer.",
          "If you press CTRL + SHIFT + P, the screencap will use your existing themes",
          "There may be some inconsistent stylings in the screencap, but unfortunately this is a limitation of the library we are using",
        ],
      },
      "Keybinds can always be found again at the bottom of the Settings Drawer",
    ],
  },
  {
    version: "1.4.1",
    date: "2024-09-14",
    changes: [
      "Added icon and banner to compendium articles from expansions",
      "Bugfix for dynamic links in compendium articles",
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: 'Added "Expansion Articles" setting',
        items: [
          'Found under the "Compendium" section of the Settings Drawer',
          "If toggled on, all articles from all expansions will be visible in the compendium",
          "If toggled off, only articles from expansions enabled in your current group will be visible.",
          "If no group is selected, no expansions are enabled",
          "Setting is toggled off by default",
        ],
      },
    ],
  },
  {
    version: "1.4.0",
    date: "2024-09-14",
    changes: [
      "Added loading screen when creating a new Group, Character, Ship or GM.",
      "Bugfixes related to loading invalid sheets",
      {
        type: BULLET_LIST_ITEM_TYPE.NORMAL,
        text: "Modified routing architecture",
        items: [
          {
            type: BULLET_LIST_ITEM_TYPE.WARNING,
            text: (
              <span>
                If you encounter problems with the new app routing, please
                notify us at <u>{supportEmail}</u> and use the{" "}
                <Link href={stableLink}>stable version</Link> of the site in the
                meantime
              </span>
            ),
          },
        ],
      },
    ],
  },
  {
    version: "1.3.0",
    date: "2024-09-12",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: 'Added "Expansions" section to the Groups page',
        items: [
          'The toggle to enable expansions is located in the "Group Info" section. It is only modifiable by an Owner.',
          'The first "expansion" we\'re supporting is the Reaches from the base Wildsea book.',
          "This includes 2 bloodlines (Corron and Dawheh-Whe) and some ship Design options",
          <span>
            Content from an expansion will often be accompanied by its
            corresponding logo. For example, the Reaches (Base game) logo is{" "}
            <TelescopeIcon
              style={{ height: "18px", width: "18px", marginBottom: "-2px" }}
            />
          </span>,
        ],
      },
    ],
  },
  {
    version: "1.2.3",
    date: "2024-09-11",
    changes: [
      "Bugfixes in Character Creator and Compendium",
      "Updated stable version to v1.2.1",
    ],
  },
  {
    version: "1.2.2",
    date: "2024-08-25",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added ability to export sheets to JSON.",
        items: [
          "Try it out by clicking CTRL + S while on any character, ship, or GM.",
          "You can find this and other useful keybinds at the bottom of the settings drawer.",
        ],
      },
    ],
  },
  {
    version: "1.2.1",
    date: "2024-08-21",
    changes: [
      "Added UI in dice roller for adding cut to a roll.",
      "Re-organized settings drawer.",
      "Added several settings to let users customize their dice, cut, and chat behavior.",
    ],
  },
  {
    version: "1.2.0",
    date: "2024-08-12",
    changes: [
      <span>
        Added ability to ping certain sections of sheets into the chat! Look for
        the <MessageSquareText width={15} height={15} /> icon in your sheets!
      </span>,
      "Added feature to allow new accounts to set up their profile settings immediately after creation",
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: "Added new login method: email + password ",
        items: [
          `If you'd like to add a password to an existing account, click the "Forgot password?" link on the sign-in page and follow the on-screen instructions.`,
          `gmail-based "email + password" accounts will still be able to use the "Sign in with Google" button`,
          {
            type: BULLET_LIST_ITEM_TYPE.WARNING,
            text: `If you use a fake email, we will not be able to reset your password!`,
          },
          <span>
            If you run into any login issues, please contact{" "}
            <u>charsmithapp@gmail.com</u>
          </span>,
        ],
      },
    ],
  },
  {
    version: "1.1.1",
    date: "2024-08-12",
    changes: ["Added 2 new themes: Pink (Dark) and Pink (Light)"],
  },
  {
    version: "1.1.0",
    date: "2024-08-09",
    changes: [
      {
        type: BULLET_LIST_ITEM_TYPE.WARNING,
        text: (
          <>
            <span>
              Updated Site Routing Architecture - while big for the dev team,
              you will hopefully notice no changes in behavior
            </span>
            <br />
            <br />
            <div>
              If you notice any issues, please send an email to{" "}
              <u>charsmithapp@gmail.com</u>,
            </div>
          </>
        ),
      },
      {
        type: BULLET_LIST_ITEM_TYPE.IMPORTANT,
        text: (
          <>
            <span>
              We've created a 'stable' version of the app with the last stable
              version of the app. If you are unable to use the app due to an
              update, try using{" "}
              <a href={stableLink} className={styles["link"]}>
                {stableLink}
              </a>
            </span>
            <br />
            <br />
            <div>The stable build of the site will be on version 1.0.2</div>
          </>
        ),
      },
      {
        type: BULLET_LIST_ITEM_TYPE.PATREON,
        text: "Added Mute Button - our first patreon request! 🎉",
      },
      "Bugfixes",
    ],
  },
  {
    version: "1.0.2",
    date: "2024-07-22",
    changes: [
      "Added ability to mark ratings in the ship sheet",
      'Added "By Firefly Light" logo to footer',
      "Added confirmation modal when deleting users from groups",
      "Added ability to remove yourself from a group, even if you're not the owner (available on the Groups page)",
      "Added confirm button when joining a group",
      'Added "Clear local storage and refresh" button on error page to help stuck users',
      "Bugfixes",
    ],
  },
  {
    version: "1.0.1",
    date: "2024-06-24",
    changes: [
      'Added this "Changelog" 🙂',
      'Fixed functionality of "ratings" in the ship sheet to match how they should be used in the rules',
      "Added ability to select Wildsailor portraits in the User section of the Settings Drawer",
      "Bugfixes",
    ],
  },
  {
    version: "1.0.0",
    date: "2024-06-19",
    changes: ["Initial launch! 🎉"],
  },
];

export const surveyVersionsList = versions
  .filter((v) => v.survey)
  .map((v) => v.version);
export const surveyVersions = mapValues(
  keyBy(surveyVersionsList, identity),
  () => true
);

export const currentVersion = versions[0].version;
export const latestSurveyVersion = findLast(versions, (v) => v.survey)?.version;
