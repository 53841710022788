import { useEffect, useState } from "react";
import styles from "./baseImage.module.scss";
import classnames from "classnames/bind";
import { FileX2 } from "lucide-react";
import { Tooltip } from "../Tooltip/Tooltip";

const classNameBuilder = classnames.bind(styles);

export const IMAGE_POSITIONS = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right",
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  PORTRAIT: "portrait",
};

export const BaseImage = ({
  className,
  imageClassName,
  errorIconClassName,
  src,
  round,
  rounded,
  alt = "Image",
  width,
  height,
  size,
  position,
  contain,
  objectPosition,
  ...rest
}) => {
  const [error, setError] = useState(null);
  let finalWidth = width;
  let finalHeight = height;
  if (size) {
    finalHeight = size;
    finalWidth = size;
  }

  useEffect(() => {
    if (error) {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <div
      className={classNameBuilder("container", className)}
      style={{ width: finalWidth, height: finalHeight }}
    >
      {error ? (
        <FileX2
          className={classNameBuilder("icon", errorIconClassName)}
          data-srcurl={src}
        >
          <Tooltip>Unable to load image...</Tooltip>
        </FileX2>
      ) : (
        <img
          src={src}
          alt={alt}
          className={classNameBuilder("image", imageClassName, position, {
            round,
            contain,
            rounded,
          })}
          style={{
            objectPosition,
          }}
          onError={() => setError(true)}
          {...rest}
        />
      )}
    </div>
  );
};
