import { Submarine } from "components/Common/Icons/Submarine";
import { TelescopeB } from "components/Common/Icons/TelescopeB";
import { TelescopeS } from "components/Common/Icons/TelescopeS";
import { values } from "lodash";
import { CloudLightning, Plane } from "lucide-react";

export const EXPANSIONS = {
  SAR: "SAR",
  SAR_AIR: "SAR_AIR",
  SAR_SUB: "SAR_SUB",
  SAR_REACHES: "SAR_REACHES",
  BASE_REACHES: "BASE_REACHES",
};

export const EXPANSIONS_VALUES = values(EXPANSIONS);

export const EXPANSIONS_CONFIG = {
  [EXPANSIONS.BASE_REACHES]: {
    label: "Reaches (Base game)",
    logo: TelescopeB,
  },
  [EXPANSIONS.SAR]: {
    label: "Storm and Root",
    logo: CloudLightning,
  },
  [EXPANSIONS.SAR_AIR]: {
    label: "Storm and Root - Airship",
    logo: Plane,
  },
  [EXPANSIONS.SAR_SUB]: {
    label: "Storm and Root - Submarine",
    logo: Submarine,
  },
  [EXPANSIONS.SAR_REACHES]: {
    label: "Reaches (Storm and Root)",
    logo: TelescopeS,
  },
};
