import { useQuery } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import { versions } from "components/ChangeLog/versions";
import { Dropdown } from "components/Common/Dropdown";
import { Loader } from "components/Common/Loader";
import { convertToDropdownOption } from "components/Common/Roller/utils/rollerUtils";
import { useNavigator } from "components/Router/NavigatorContext";
import { useSurveysAccessors } from "contexts/database/useSurveyAccessors";
import {
  compact,
  countBy,
  entries,
  find,
  identity,
  keys,
  omit,
  size,
  sortBy,
  values,
} from "lodash";
import React, { useMemo } from "react";
import styles from "./surveySummary.module.scss";
import { Section } from "components/Common/Section";
import { EMPTY_LIST } from "constants/defaults";
import { SURVEY_TYPES } from "components/ChangeLog/surveys";
import { Tooltip } from "components/Common/Tooltip/Tooltip";

const versionOptions = versions
  .filter((v) => v.survey)
  .map((v) => convertToDropdownOption(v.version));

export const SurveySummary = () => {
  const { version } = useParams({ strict: false });
  const { navigate } = useNavigator();

  const { read } = useSurveysAccessors();

  const { data: surveyResults, isFetching } = useQuery({
    queryKey: ["SURVEY_SUMMARY", version, versionOptions],
    queryFn: () => (find(versionOptions, { id: version }) ? read(version) : {}),
  });
  const hasResults = !size(surveyResults);
  const surveyEntries = useMemo(() => {
    return values(surveyResults)
      .filter((r) => r?.date)
      .map(({ date, ...rest }) => ({
        ...rest,
        date: new Date(date.seconds * 1000),
      }));
  }, [surveyResults]);
  const surveyQuestionConfigs = useMemo(
    () =>
      find(versions, { version })?.survey?.questions?.map((q, i) => ({
        key: i,
        ...q,
      })) || EMPTY_LIST,
    [version]
  );

  const aggregations = useMemo(() => {
    return surveyQuestionConfigs.map(({ type, key, label }) => {
      let questionResults = surveyEntries.map((r) => r[key]);

      if (type === SURVEY_TYPES.MULTI_SELECT) {
        questionResults = questionResults.map((r) =>
          sortBy(keys(r), identity).join(", ")
        );
      }

      questionResults = compact(questionResults);

      return {
        count: sortBy(
          entries(countBy(questionResults, identity)),
          ([_, count]) => count * -1
        ),
        wordCount: sortBy(
          entries(
            countBy(
              omit(
                questionResults
                  .map((q) => compact(q.split(/[\s,]+/)))
                  .flat()
                  .map((v) => v.toLowerCase()),
                ["a", "the", "an", "and"]
              ),
              identity
            )
          ),
          ([_, count]) => count * -1
        ),
        questionResults,
        label,
      };
    });
  }, [surveyEntries, surveyQuestionConfigs]);

  return (
    <div>
      <div>
        Survey Summary ({version}) {isFetching && <Loader />}
      </div>
      <Dropdown
        selected={version}
        setSelected={(v) => navigate({ to: `/survey-summary/${v}` })}
        items={versionOptions}
      />
      {hasResults ? (
        <div>No results...</div>
      ) : (
        <div>
          <div className={styles["aggregations"]}>
            <h2>Aggregations</h2>
            {aggregations.map(({ count, label, questionResults }) => (
              <Section
                key={label}
                label={label}
                expandable
                initialExpanded
                contentClassName={styles["aggregation"]}
              >
                <div>Total responses: {size(questionResults)}</div>
                <ul>
                  {count.map(([response, count], i) => (
                    <li key={i}>
                      <span>
                        ({count}): {response.substr(0, 50)}
                        <Tooltip interactive>{response}</Tooltip>
                      </span>
                    </li>
                  ))}
                </ul>
              </Section>
            ))}
            <h2>Word Count</h2>
            {aggregations.map(({ wordCount, label }) => (
              <Section
                key={label}
                label={label}
                expandable
                contentClassName={styles["aggregation"]}
              >
                <ul>
                  {wordCount.map(([response, count], i) => (
                    <li key={i}>
                      <span>
                        ({count}): {response}
                        <Tooltip interactive>{response}</Tooltip>
                      </span>
                    </li>
                  ))}
                </ul>
              </Section>
            ))}
          </div>
          <h2>Individual Responses ({size(surveyEntries)})</h2>
          <div className={styles["individual-results"]}>
            {surveyEntries.map((result, i) => {
              return (
                <Section key={i}>
                  <div>Date: {result.date.toLocaleString()}</div>
                  <div>
                    {surveyQuestionConfigs.map(({ key, type, label }) => {
                      return (
                        <div key={key}>
                          <u>{label}:</u>{" "}
                          <span>
                            {(type === SURVEY_TYPES.MULTI_SELECT
                              ? keys(result[key]).join(", ")
                              : result[key]) || "None"}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </Section>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
