import { BulletList } from "components/Common/List/BulletList";
import { TUTORIALS } from "components/Tutorial/consts/tutorialConsts";
import { useTutorial } from "contexts/database/TutorialContext";
import { Modal } from "components/Common/Modal";
import { usePortal } from "contexts/PortalContext";
import { memo, useEffect, useMemo, useRef } from "react";
import { latestSurveyVersion, stableVersion, versions } from "./versions";
import styles from "./changeLog.module.scss";
import { useAuth } from "contexts/AuthContext";
import { DIRECTION, Divider } from "components/Common/Divider";
import * as semver from "semver";
import { stableLink } from "constants/contactInfo";
import { ChangeLogSurvey } from "./ChangeLogSurvey";
import { EMPTY_OBJECT } from "constants/defaults";
import { useSurveysAccessors } from "contexts/database/useSurveyAccessors";
import { uuid } from "lib/uid";
import { Button } from "components/Common/Button";
import { Bell } from "lucide-react";

export const ChangeLog = ({ portalProps, subtitle, versionDivider }) => {
  const { user, updateUser } = useAuth();
  const { updateLatestSync: updateSurvey, addIgnoreWithId: addIgnoreSurvey } =
    useSurveysAccessors();

  const { surveys: finishedSurveys = EMPTY_OBJECT } = user || EMPTY_OBJECT;
  const latestSurveyVersionRef = useRef();

  return (
    <Modal
      portalProps={portalProps}
      title="Changelog"
      subtitle={subtitle}
      contentClassName={styles["modal-content"]}
      closePrefix={
        <>
          <div className={styles["spacer"]} />
          {!finishedSurveys[latestSurveyVersion] && (
            <Button
              className={styles["jump-button"]}
              onClick={() =>
                latestSurveyVersionRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
            >
              <Bell className={styles["bell"]} />
              Jump to Latest Survey
            </Button>
          )}
        </>
      }
      headerProps={{
        className: styles["modal-header"],
      }}
    >
      {versions.map(({ version, date, changes, survey }, index) => (
        <div key={version}>
          {index !== 0 && version === versionDivider && (
            <Divider direction={DIRECTION.HORIZONTAL} />
          )}
          <div className={styles["version-container"]}>
            <div className={styles["version"]}>
              <span>{`v${version}`}</span>
              {stableVersion === version && (
                <>
                  {" "}
                  <span>
                    [
                    <a
                      href={stableLink}
                      target="_blank"
                      className={styles["version-link"]}
                      rel="noreferrer"
                    >
                      STABLE
                    </a>
                    ]
                  </span>
                </>
              )}
            </div>
            <div>{`(${date})`}</div>
          </div>
          <BulletList items={changes} />
          {survey && (
            <ChangeLogSurvey
              survey={survey}
              version={version}
              latestSurveyVersionRef={
                version === latestSurveyVersion ? latestSurveyVersionRef : null
              }
              updateUser={updateUser}
              user={user}
              finished={finishedSurveys[version]}
              onSubmit={async (response) => {
                try {
                  const surveyEntry = {
                    [uuid()]: {
                      ...response,
                      date: new Date(),
                    },
                  };
                  await updateUser(() => ({
                    surveys: { ...finishedSurveys, [version]: true },
                  }));
                  const doc = await addIgnoreSurvey(version, surveyEntry, {
                    disableReadExisting: true,
                  });

                  console.log(doc);

                  // if there's not a document, then this entry already existed, so update the doc
                  if (!doc) {
                    await updateSurvey(version, () => surveyEntry);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            />
          )}
        </div>
      ))}
    </Modal>
  );
};

export const InitialLoadChangeLog = memo(() => {
  const { user, updateUser } = useAuth();
  const lastLoadedVersion = user?.lastLoadedVersion;
  // cache the first truthy version of lastLoadedVersion because updateUser will mutate it
  const versionDivider = useMemo(
    () => lastLoadedVersion,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Boolean(lastLoadedVersion)]
  );
  const portalProps = usePortal();
  const { getTutorialStatus } = useTutorial();
  const introTutorialStatus = getTutorialStatus(TUTORIALS.INTRO);
  const newestVersion = versions[0].version;
  const hasNewerVersion =
    introTutorialStatus !== 0 &&
    user &&
    (!lastLoadedVersion || semver.lt(lastLoadedVersion, newestVersion));
  useEffect(() => {
    if (hasNewerVersion) {
      portalProps.setIsPortalComponentRendered(true);
      updateUser(() => ({
        lastLoadedVersion: newestVersion,
      }));
    }
  }, [
    hasNewerVersion,
    portalProps,
    updateUser,
    newestVersion,
    lastLoadedVersion,
  ]);
  return (
    <ChangeLog
      portalProps={portalProps}
      subtitle={"We've released an update!"}
      versionDivider={versionDivider}
    />
  );
});
