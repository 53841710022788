import { useEffect, useState } from "react";
import styles from "./uploadAndSubmitImage.module.scss";
import classnames from "classnames/bind";
import { AutoInputWithButton } from "../InputWithButton";
import { Upload } from "lucide-react";
import { FooterButtons } from "../FooterButtons/FooterButtons";
import { BaseImage, Image } from "../Image/Image";
import { Input } from "../Input";
import { get, noop } from "lodash";
import { uploadFileToCDN, uploadUrlToCDN } from "lib/imageUtils";
import { LoaderWithText } from "../Loader";

const classNameBuilder = classnames.bind(styles);

// const FIVE_HUNDRED_KB = 500000;
// const RESIZE_SIZE = 450;
const MAX_URL_LENGTH = 1000;

export const UploadAndSubmitImage = ({
  className,
  imageContainerClassName,
  imageClassName,

  shouldRenderBaseImage,

  initialValue,
  onChange = noop,
  onSubmit,

  hideSubmit,
  hideReset,
  hideButtons,
  submitText,
  imageProps,
  autoFocus,
}) => {
  const [value, setValue] = useState(initialValue);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChangeValue = async (val) => {
    let finalValue = val;

    if (val.length > MAX_URL_LENGTH) {
      try {
        finalValue = await uploadUrlToCDN(val);
      } catch (err) {
        console.error(err.message);
        alert(
          `URL was too long (${MAX_URL_LENGTH} characters). Attempted to upload to CDN but failed....\n\nPlease try again with a shorter URL.`
        );

        return;
      }
    }
    setValue(finalValue);
    onChange(finalValue);
  };

  const ImageComponent = shouldRenderBaseImage ? BaseImage : Image;

  return (
    <div className={classNameBuilder("container", className)}>
      <div
        className={classNameBuilder("image-container", imageContainerClassName)}
      >
        {value && (
          <ImageComponent
            imageClassName={imageClassName}
            src={value}
            alt="selected"
            contain
            {...imageProps}
          />
        )}
      </div>
      <AutoInputWithButton
        placeholder="Enter an image URL"
        onSubmit={(v) => {
          onChangeValue(v);
        }}
        reverse
        autoFocus={autoFocus}
        buttonChildren={<Upload className={styles["upload-icon"]} />}
        containerClassName={styles["input"]}
      />
      <span className={styles["or"]}>OR</span>
      {isUploading && <LoaderWithText>Please wait a moment...</LoaderWithText>}
      <Input
        className={classNameBuilder("file-upload", { hidden: isUploading })}
        disabled={isUploading}
        type="file"
        accept="image/png, image/jpg"
        onChange={async (e) => {
          let file = get(e, "target.files.0");
          if (!file) {
            return;
          }

          let cloudinaryUrl;
          setIsUploading(true);
          try {
            cloudinaryUrl = await uploadFileToCDN(file);
          } catch (err) {
            setIsUploading(false);
            console.error(err);
            alert(err.message);

            return;
          }

          setIsUploading(false);
          onChangeValue(cloudinaryUrl);
        }}
      />
      {!hideButtons && (
        <FooterButtons
          onCancel={hideReset ? undefined : () => setValue(initialValue)}
          onSubmit={hideSubmit ? undefined : (e) => onSubmit(e, value)}
          cancelText="Reset"
          submitText={submitText}
          submitDisabled={isUploading || !value}
        />
      )}
    </div>
  );
};
