import styles from "./portrait.module.scss";
import portrait0 from "./images/portrait0.png";
import { IMAGE_POSITIONS, Image } from "components/Common/Image/Image";
import { PickOrUploadModal } from "components/Common/PickOrUploadModal.js/PickOrUploadModal";
import classnames from "classnames/bind";
import { PencilIcon, Search } from "lucide-react";
import { Tooltip } from "components/Common/Tooltip/Tooltip";
import { usePortal } from "contexts/PortalContext";
import { PreviewImageModal } from "../Portals/PreviewImageModal/PreviewImageModal";
import { usePortraitImages } from "../hooks/usePortraitImages";

export const DEFAULT_PORTRAIT = portrait0;

const classNameBuilder = classnames.bind(styles);

export const Portrait = ({
  value,
  onClick,
  fetchingCharacters,
  portalProps,
  portalComponentProps,
  className,
  defaultValue = DEFAULT_PORTRAIT,
  defaultImagesPrefix,
  defaultImagesSuffix,
  defaultImages: propDefaultImages,
  imageContainerClassName,
  imageProps,
  shouldRenderPreview,
  overlayContent = "Click to change",
  forceShowOverlay,
  forceHoverButtons,
}) => {
  const shouldRenderModal = Boolean(portalProps);
  const imagePortal = usePortal();

  const portraitImages = usePortraitImages({
    prefix: defaultImagesPrefix,
    suffix: defaultImagesSuffix,
  });

  return (
    <div className={classNameBuilder("container", className)}>
      {shouldRenderModal && (
        <>
          <PickOrUploadModal
            portalProps={portalProps}
            initialValue={value || defaultValue}
            title="Pick a Portrait"
            defaultName="Wildsailor"
            defaultImages={propDefaultImages || portraitImages}
            imageContainerClassName={classNameBuilder(
              "image-container",
              imageContainerClassName
            )}
            imageProps={{
              position: IMAGE_POSITIONS.PORTRAIT,
              contain: false,
            }}
            {...portalComponentProps}
            key={portalComponentProps?.key}
          />
          <div
            className={classNameBuilder("icon", "edit", {
              "force-hover": forceHoverButtons,
            })}
            onClick={() => portalProps?.setIsPortalComponentRendered(true)}
          >
            <PencilIcon>
              <Tooltip position="top">Click to Edit</Tooltip>
            </PencilIcon>
          </div>
        </>
      )}
      {shouldRenderPreview && (
        <>
          <div
            className={classNameBuilder("icon", "preview", {
              "force-hover": forceHoverButtons,
            })}
            onClick={(e) => {
              e.stopPropagation();
              imagePortal.setIsPortalComponentRendered(true);
            }}
          >
            <Search />
            <Tooltip position="top-start">Click to Preview</Tooltip>
          </div>
          <PreviewImageModal
            value={value || defaultValue}
            portalProps={imagePortal}
            {...imageProps}
          />
        </>
      )}
      {!fetchingCharacters && (
        <Image
          src={value || defaultValue}
          alt="portrait"
          onClick={onClick}
          position={IMAGE_POSITIONS.PORTRAIT}
          {...imageProps}
        />
      )}
    </div>
  );
};
