import { getRandValue } from "lib/arrayUtils";
import { fill, filter, get, identity, isNil } from "lodash";
import defaultStyles from "../consts/defaultRollers.module.scss";
import classnames from "classnames/bind";
import { getDecimalChance } from "lib/numberUtils";

const defaultBuilder = classnames.bind(defaultStyles);

// utils
export const getFirstResult = (arr) => get(arr, "0.label", "");

// converters
export const convertToDropdownOption = (label, index) => ({
  id: isNil(index) ? label : index,
  label,
});
export const convertToDropdownOptions = (arr, { shouldUseId } = {}) =>
  arr.map((v, i) => convertToDropdownOption(v, shouldUseId ? null : i));
export const convertConfigArrToDropdownOptions = (arr) =>
  arr.map((config) => ({
    ...convertToDropdownOption(config.label),
    expansion: config.expansion || "",
  }));
export const convertConfigToDropdownOptions = (config) =>
  convertConfigArrToDropdownOptions(Object.values(config));

export const getOptionalOptions = (
  options,
  decimalChance,
  defaultString = "None"
) => [
  ...fill(
    new Array(Math.round((1 - decimalChance) * options.length)),
    defaultString
  ),
  ...options,
];

export const getArrFromWeights = (weights, parser = parseInt) => {
  let res = [];

  Object.entries(weights).forEach(([key, value]) => {
    res = [...res, ...fill(new Array(value)).map(() => parser(key))];
  });

  return res;
};

// generators
export const getRandomNamesGenerator =
  (
    syllableOptions,
    normalSyllables,
    allSyllables,
    veryRareSyllables,
    { modifier = identity } = {}
  ) =>
  (numNames) => {
    const newResult = fill(new Array(numNames));
    return newResult.map(() => {
      const numSyllables = getRandValue(syllableOptions);
      let newName = fill(new Array(numSyllables));
      newName = newName.map((_, i) => {
        let syllables = allSyllables;

        if (veryRareSyllables && Math.floor(Math.random() * 100) === 1) {
          syllables = veryRareSyllables;
        } else if (i === 0 || i === newName.length - 1) {
          syllables = normalSyllables;
        }

        return getRandValue(syllables);
      });
      newName[0] = newName[0][0].toUpperCase() + newName[0].slice(1);
      newName = modifier(newName);
      return newName.join("");
    });
  };
export const getCappedRandomResults =
  (cap, { repeatLikelihood = 0 } = {}) =>
  (num, { options }) => {
    const resultsMap = {};
    let availableOptions = options;

    for (let i = 0; i < num; i++) {
      let randVal;
      let curMapVal;
      let id;

      const repeatableVals = filter(
        Object.values(resultsMap),
        (o) => o.count < cap
      );

      if (
        repeatableVals.length > 0 &&
        repeatLikelihood &&
        getDecimalChance(repeatLikelihood)
      ) {
        curMapVal = getRandValue(repeatableVals);
        id = curMapVal.id;
      } else {
        randVal = getRandValue(availableOptions);
        id = randVal.id;
        curMapVal = resultsMap[id];
      }

      if (curMapVal) {
        curMapVal.count++;

        if (curMapVal.count === cap) {
          availableOptions = availableOptions.filter((o) => o.id !== id);
        }
      } else {
        resultsMap[id] = { ...randVal, count: 1 };
      }

      if (availableOptions.length === 0) {
        break;
      }
    }

    return Object.values(resultsMap);
  };

// result strings
export const getDefaultResultString = (result) => {
  return result.map((r) => r.label).join(", ");
};
export const getLabelDescriptionResultString = (result) => {
  const entries = result.map((r, i) => {
    return (
      <p key={i}>
        <u
          className={defaultBuilder({
            [r.archetype]: r.archetype,
          })}
        >
          {r.label}:
        </u>{" "}
        {`${r.description}`}
      </p>
    );
  });

  return entries;
};
export const getGroupedResultString = (result) => {
  const val = {};
  result.forEach(({ label: r }) => {
    val[r] = val[r] ? val[r] + 1 : 1;
  });

  return Object.entries(val)
    .map(([key, value]) => {
      return `${key} (${value})`;
    })
    .sort()
    .join(", ");
};
export const getCSVResultString = (result) => result.join(", ");
export const getNameResults = (result) =>
  result.join("\u0020\u00A0·\u00A0\u0020");

export const getCappedResultString = (result) =>
  result.map((r) => `${r.label} (${r.count})`).join(", ");
export const COUNT_TO_FLUENCY = {
  1: "Smattering",
  2: "Knowledge",
  3: "Fluency",
};
export const COUNT_TO_FLUENCY_V2 = {
  1: "Smattering",
  2: "Knowledge",
  3: "Fluent",
};
export const getCappedLanguagesResultString = (result) =>
  result.map((r) => `${r.label} (${COUNT_TO_FLUENCY[r.count]})`).join(", ");
