import { useTheme } from "contexts/ThemeContext";
import { isNil } from "lodash";
import styles from "./crashPage.module.scss";
import classnames from "classnames/bind";
import { HeartCrack } from "lucide-react";
import { Button } from "components/Common/Button";
import { EmailLink } from "components/Footer/FooterLink";
import { stableLink } from "constants/contactInfo";

const classNameBuilder = classnames.bind(styles);

const LAST_CHUNK_ERROR_KEY = "LAST_CHUNK_ERROR";

let reloadingApp = false;

const ChunkErrorMessageStrings = [
  "Loading chunk",
  "ChunkLoadError:",
  "Loading CSS chunk",
];
const checkIsChunkCrash = (msg) => {
  return msg && ChunkErrorMessageStrings.some((str) => msg.indexOf(str) !== -1);
};

export const RefreshText = () => {
  return (
    <div className={styles["refresh-text"]}>
      Sorry for the inconvenience! Please try reloading the page...
      <br />
      <br />
      <span className={styles["mini-text"]}>
        <span className={styles["reset-container"]}>
          <span>
            If you continue to receive this bug: please click the button below
            to try to resolve the issue
          </span>
          <Button
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Clear local storage and refresh
          </Button>
        </span>
      </span>
      <br />
      <span className={classNameBuilder("mini-text", "contact-prompt")}>
        <div className={styles["email"]}>
          If you think a feature in the app is broken or you're unable to access
          or use the app, please contact the following email including the gray
          text at the bottom of the page:
        </div>
        <EmailLink />
        <br />
        <div>
          If you are unable to use the app due to this bug, you can try using
          our stable version of the app:
        </div>
        <a href={stableLink} className={styles["link"]}>
          {stableLink}
        </a>
        <br />
      </span>
    </div>
  );
};

export const CrashPageContent = ({ error }) => {
  return (
    <>
      <div className={styles["title"]}>Something went wrong :(</div>
      <HeartCrack className={styles["fallback-icon"]} />
      <RefreshText />
      <div className={styles["error-message"]}>
        Error: {error?.message || "Unknown error"}
      </div>
    </>
  );
};

export const CrashPage = ({ error }) => {
  const { themeClassNames } = useTheme();

  const lastError = localStorage.getItem(LAST_CHUNK_ERROR_KEY);
  const hasSameError = !isNil(lastError) && lastError === error?.message;

  if (reloadingApp) {
    return <div className={styles["fallback"]}></div>;
  }

  // if failed b/c missing chunk, refresh the page
  if (checkIsChunkCrash(error?.message) && !hasSameError) {
    reloadingApp = true;
    localStorage.setItem(LAST_CHUNK_ERROR_KEY, error?.message);
    window.location.reload();
    return <div className={styles["fallback"]}></div>;
  }

  return (
    <div
      className={classNameBuilder("fallback", ...themeClassNames)}
      role="alert"
    >
      <div className={styles["fake-header"]} />
      <CrashPageContent error={error} />
    </div>
  );
};
