import styles from "./textarea.module.scss";
import classnames from "classnames/bind";

const classNameBuilder = classnames.bind(styles);

export const Textarea = ({ className, ...rest }) => {
  return (
    <textarea className={classNameBuilder("textarea", className)} {...rest} />
  );
};
